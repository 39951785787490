.livelyContainer{
  padding: 0px;
}

.swipe video{ display: block;}

.search {
  font-size: 32px;
  line-height: 0;
}

.searchInput {
  width: 100%;
  padding: 10px;
  font-size: 18px;
  border: 0 none;
  border-bottom: 1px solid #ccc;
}

.searchInput::placeholder {
  font-style: italic;
  color: #bbb;
}

.amexBanner{
  display: block;
  margin: auto;
}

.HomeCategoryIcon{
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
}

.contentParent{
  display: flex;
  flex-direction: column;
  text-align: center;
}

.belowHeading{
  color: #3E4245;
  margin-bottom: 1.25rem;
  font-size: 2.5rem !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 52px;
  padding-top: 1rem;
}

.belowDescription{
  color: #3E4245;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;  
  margin-bottom: 2rem;
}

.noBorderGifting{
  border-radius: 0 !important;
}

.noBorderGifting a {
  border-radius: 0 !important;
  display: flex !important;
}

.giftingParent{
  display: flex;
  flex-wrap: wrap;
}

.image-leaf{
  display: none;
}

.rightLeaf{
  position: absolute;
    right: 0;
    width: 100%;
    height: auto;
}


.home_mobile_banner h1 { text-transform: uppercase;  font-size: 22px;   letter-spacing: 0.15px;  font-weight: 500; font-family: Cormorant Garamond; padding-top: 25px; padding-bottom: 6px;}
.home_mobile_banner p { margin-bottom: 15px; font-size: 14px; letter-spacing: 0.25px;    margin: 0;}	
.home_mobile_banner	.banner_btn{ width: 100%; display: table; border-radius: 5px; font-weight: 600; text-transform: uppercase; 
  background: #C4601D;letter-spacing: 1.25px;font-size: 12px;color: #ffffff; padding: 13px 25px; text-align: center; min-width: 172px;}
.home_mobile_sec1{ display:inline-block; margin-top: 40px; width: 100%;}
.home_mobile_sec1 h2{font-size: 24px; float: left; line-height: 1;font-weight: 500; color: #222222;}
.category_product_mobile { box-shadow: 0px 0px 6px rgb(82 53 40 / 16%); width: 100%;  border-radius: 5px; background: #fff;}
.category_product_mobile_img {  padding: 15px 10px; position: relative;}
.category_product_mobile h3 { color: #000; padding: 0 15px;   font-size: 18px;  line-height: 1.3;  font-weight: 600;  padding-bottom: 10px;}
.category_product_mobile p { padding: 0 15px; margin-bottom: 15px; font-size: 14px;}
.category_product_mobile h4{ font-weight: 600; padding: 0 15px;font-size:20px;font-family: Open Sans; line-height: 1.2; padding-bottom: 20px;}

.category_product_mobile_img .like_colm { position: absolute; right: 15px; top: 15px;}
.home_mobile_sec2{ display:inline-block;  width: 100%; position: relative;}
.home_mobile_sec2 span img{ display: block;}
.home_mobile_sec2 h3{letter-spacing: 0.15px;font-size: 26px; text-transform: uppercase; font-weight: 500; padding-bottom: 8px; line-height: 1.2;}
.home_mobile_sec2 p{font-size: 14px;letter-spacing: 0.25px;}
.home_mobile_sec2 li { float: left; margin-right: 10px;color: #C4601D;font-size: 12px;letter-spacing: 0.4px;text-transform: uppercase; font-weight: 600;}
.home_mobile_sec2 li img{ width: 14px;}
.home_mobile_sec2 li a {float: left;margin: 0 4px;    position: relative;top: 2px;}
.home_mobile_sec2li span{ margin-right: 5px; float: left;}
.home_mobile_sec2 li a img { width: auto;}
.home_mobile_sec2 ul { display:table; margin: 0 auto; margin-bottom: 15px;}
.home_mobile_sec2 a{ font-weight: 600;    letter-spacing: 1.25px; font-size: 12px;background: #C4601D; color: #fff; padding: 13px; border-radius: 5px;text-align: center;display: table; width: 100%; text-transform: uppercase;}
.home_mobile_sec2 span { overflow: hidden;display: table;   border-radius: 5px; margin-bottom: 0px;}
/* .home_mobile_sec2:before{content: ""; background-image: url(../../public/images/motif1.svg); 
  width: 156px;height: 49px;transform: translate(-50%, 0); position: absolute; left: 50%; top: -49px;
   background-size: cover;} */

.home_mobile_sec3 {  display: block;
width: 100%;  margin-top: 35px; padding: 20px 15px;  box-shadow: 0px 0px 6px rgb(82 53 40 / 20%); border-radius: 5px;    background-repeat: no-repeat;background-size: contain;}
.home_mobile_sec3 span { display: block;padding-bottom: 0px;}
.home_mobile_sec3 span div{display: block !important; }
.home_mobile_sec3 h3{font-size: 26px; text-transform: uppercase;   letter-spacing: 0.15px; font-weight: 500; padding-bottom: 10px; line-height: 1.2;}
.btn_white {border-radius: 5px; font-size: 12px;  letter-spacing: 1.25px; text-transform: uppercase; display: table; color: #C4601D; text-align: center; padding: 12px; border: 1px solid #a9671e; width: 100%; font-weight: 600;}
.home_mobile_sec4{display: inline-block; width: 100%;  margin-top:40px;margin-bottom: 40px;}
.home_mobile_sec4 h2{font-size: 24px; font-weight: 500; color: #222222; padding-bottom: 12px;text-align: left; }

.home_mobile_sec4 span{    border-radius: 0px;margin-bottom:15px; display: block; width: 100%;
text-align: center;overflow: hidden;}
.home_mobile_sec4 h3{font-size: 22px;letter-spacing: 0.15px; line-height: 1.2;margin-bottom:8px; font-family: 'Bell MT'; color: #222222;}
.ome_mobile_sec4 p{font-size: 14px;letter-spacing: 0.25px; margin-bottom: 18px;}
.home_mobile_sec5{ display: table;  width: 100%;}
.home_mobile_sec5 h2{font-size: 20px; text-transform: uppercase; font-weight: 600; padding-bottom: 12px;}
.home_mobile_sec5 img{ display: block;}
.home_mobile_sec5 ul li { position: relative;width: 50%; float: left;  padding: 0 7px; margin-bottom: 14px;}
.home_mobile_sec5 ul{ margin: 0 -7px;}
.home_mobile_sec5 ul li span{overflow: hidden;  display: block; border-radius: 5px; cursor: pointer;}
.home_mobile_sec5 ul li span div, .home_mobile_sec4 span div{display: block !important; padding-bottom: 0; }
.home_mobile_sec5 ul li h5{text-transform: uppercase; cursor: pointer;  
       left: 0;letter-spacing: 1.25px; font-size: 13px; font-family: Open Sans; 
       font-weight: 600;position: absolute;text-align: center; top: 50%;
        transform: translate(0,-50%); width: 100%;padding: 0 10px; color: #fff;}
.home_mobile_sec6{display: table; margin-top: 35px;width: 100%;}
.home_mobile_sec6 span{display: table;overflow: hidden; border-radius: 0px; margin-bottom: 15px;}
.home_mobile_sec6 span div, .home_mobile_sec2 span div{ display: block !important;}
.home_mobile_sec6 h3{font-size: 24px; font-family: 'Bell MT'; color: #222222; letter-spacing: 0.15px; font-weight: 500; padding-bottom:8px; line-height: 1.2;}
.home_mobile_sec6 p{letter-spacing: 0.25px; margin-bottom: 15px;}
.home_mobile_sec6 a{ background-color: transparent; text-align: center; display: block;border-radius: 0px;font-size: 1rem; line-height: 24px; color: #222222;letter-spacing: 0.5px; padding: 9px; font-weight: 500; border: 1px solid #3e4245;}
.home_mobile_sec6 a:hover{border-color: #ad3132;}
.home_mobile_sec7 div{  padding-bottom: 40px;}
.home_mobile_sec7 div:last-child{ padding-bottom: 0;}
.home_mobile_sec7 div span{    border-radius: 0px; margin-bottom: 15px; display: block;  width: 100%;
  background-color: #f0eded; text-align: center; overflow: hidden;}
  .home_mobile_sec7 div h3{font-size: 24px;  letter-spacing: 0.15px; line-height: 1.2; margin-bottom: 8px; font-family: 'Bell MT'; color: #222222;}
  .home_mobile_sec7 div a{ font-size: 1rem; line-height: 24px; letter-spacing: 0.5px;  display: table;
 color: #222222; text-align: center; padding: 9px;  border: 1px solid #3e4245; width: 100%; font-weight: 500; font-family: 'Barlow';}
 .home_mobile_sec7 div .two_banner_btn:hover{
  border: 1px solid #ad3131;
 }
 .home_mobile_sec7 div p{font-size: 15px;letter-spacing: 0.25px;margin-bottom: 18px; font-family: 'Barlow';}
.home_mobile_sec7 { width: 100%; display: table; margin-top:26px;}
.home_mobile_sec7 h2 span { padding: 20px;  margin-bottom:47px; background: #f9f4e8; 
  border-radius: 5px; width: 100%;display: block; position: relative; margin-top:47px; text-align: center;}
.home_mobile_sec7 h2{letter-spacing: 0.15px;color: #C4601D;font-size: 22px;position: relative;}
.home_mobile_sec7 h2:before { background-image: url(../../public/images/motif1.inline.svg); content: "";  position: absolute;  width:155px; top: -47px;  height:47px; background-size: cover; transform: translate(-50%, 0);  left: 50%;}
.home_mobile_sec7 h2:after { background-image: url(../../public/images/motif1.inline.svg); content: ""; position: absolute; width:155px; bottom: -47px; height: 47px; background-size: cover;transform: translate(-50%, 0) rotate( 180deg); left: 50%;}
.home_mobile_sec8{ margin-top:40px; width: 100%;}
.home_mobile_sec8 h2{font-size: 24px; padding-bottom: 12px; font-weight: 500; text-align: left;}
.home_mobile_sec8 ul li {width: 50%;padding: 0 7px 14px;}
.home_mobile_sec8 ul li div { height:100%; padding: 12px; border-radius: 5px; display:flex; flex-direction: column; justify-content: center; align-items: center;}
.home_mobile_sec8 ul li div a{display: flex; flex-direction: column;}
.home_mobile_sec8 ul li div h5{order: 2; letter-spacing: 1.25px;font-size: 12px;text-align: center;}
.home_mobile_sec8 ul li div span {margin: 6px auto 0; display: table;}
.home_mobile_sec8 ul{ margin:0 -7px;}
.home_mobile_sec8 ul li div span img{ display: block; aspect-ratio: 1;}
.home_mobile_sec8 abbr a{font-size: 15px;color: #575757;text-align: center;
  letter-spacing: 1.25px; font-family: 'Barlow';
}
  .home_mobile_sec8 abbr a:hover{color: #222222;}
  .home_mobile_sec8 abbr{width: 100%; display: block;text-align: center;}
.see_all_btn a {font-size: 14px;color: #946134;text-align: center;font-weight:600;text-transform: uppercase;letter-spacing: 1.25px;}
.see_all_btn {width: 100%;display: table; text-align: center;}
.home_mobile_sec9{    margin-top:35px;width: 100%;}
.home_mobile_sec9 h2{font-size: 20px;text-transform: uppercase; padding-bottom: 10px; font-weight: 600;}
.home_mobile_sec9 div { width: 100%;margin-bottom: 25px;}
.home_mobile_sec9 div p{font-size: 14px;  line-height: 1.4; margin-bottom: 0;}
.home_mobile_sec9 div span {display: block;  border-radius: 5px;overflow: hidden; position: relative; margin-bottom: 12px;}
.home_mobile_sec9 div span img{ display: block;}
.home_mobile_sec10{margin-top:30px;width: 100%;    display: inline-block;}
.home_mobile_sec10 img{height: 65px;}
.home_mobile_sec10 h2{font-size: 24px; padding-bottom: 15px; font-weight: 500;}
.home_mobile_sec10 li {    flex-shrink: 0; width: 40%;}
.home_mobile_sec10 li span {display: table;margin: 0 auto;}
.home_mobile_sec10 ul { flex-wrap: nowrap; white-space: nowrap; overflow: auto;-webkit-overflow-scrolling: touch;
 overflow-scrolling: touch;-ms-overflow-style: none; scrollbar-width: none;-webkit-transform: translateZ(0);
  transform: translateZ(0); scroll-behavior: smooth; display: flex;}
  .home_mobile_sec10 ::-webkit-scrollbar{ width: 0; height: 0;}

.journal_colm{ display: flow-root; width: 100%;}
.icon_see{ float: right;font-size: 14px;float: right; padding-top: 0px; color: #575757;font-weight: 500;
  letter-spacing: 0.5px; font-family: 'Barlow';}
  .icon_see:hover{
    font-family: 'Barlow';
    color: #222222;
    font-weight: 500;
  }
  .home_mobile_sec1 small { font-size: small;}
  .btn_bag a{background: #C4601D; color: #fff; box-shadow: 0px 0px 16px rgb(82 53 40 / 16%);  border-radius: 5px; font-size: 12px;
 letter-spacing: 1.25px; text-transform: uppercase;display: block;padding: 13px; text-align: center; font-weight: 600;}
 .home_mobile_sec1 .row {flex-wrap: nowrap;  white-space: nowrap; overflow: auto; padding-top: 10px;
padding-bottom: 15px; -webkit-overflow-scrolling: touch; overflow-scrolling: touch;-ms-overflow-style: none;  scrollbar-width: none;
  -webkit-transform: translateZ(0); transform: translateZ(0);scroll-behavior: smooth; margin: 0 -7px;}
  .category_product_mobile { white-space: normal;}
  .home_mobile_sec1 .col-8{ padding: 0 7px;}
  .home_mobile_sec1 ::-webkit-scrollbar{ width: 0; height: 0;}
  .home_product_item { display: block; position: relative; width: 100%; white-space: normal;}
.home_mobile_sec1 span {display: block;overflow: hidden;border-radius: 5px; margin-bottom: 15px;}		
.home_mobile_sec1 .col{ display: table; width: 100%; padding-bottom: 15px;}
.img_product_item{display: block; overflow: hidden; border-radius: 5px; margin-bottom: 15px;}
 .img_product_item img{ display: block;}
 .home_product_item h3 {font-size: 18px; color: #000000; font-weight: 600; padding-bottom: 10px;line-height: 1.3;}
.home_product_item p { font-size: 14px; margin-bottom: 10px;}
.home_mobile_sec1 .col { display: flow-root; width: 100%; padding-bottom: 15px;}
.home_product_item h4 {font-size: 20px; font-family: Open Sans;font-weight: 600; float: left;}
.home_product_item .like_colm {float: right;}
.like_colm img{ display: block;}
.home_mobile_sec3 ul li { float: left; margin-right: 10px; color: #C4601D;  font-size: 12px; letter-spacing: 0.4px; 
   text-transform: uppercase; font-weight: 600;}
.home_mobile_sec3 ul li img{width: 14px;}
.home_mobile_sec3 ul{ width: 100%; display:flow-root; margin-bottom: 15px;}
.home_mobile_sec3 a, .home_mobile_sec4 a{  line-height: 24px;  background-color: transparent; text-align: center; display: block; font-size: 1rem;
 color: #222222;letter-spacing: 0.5px;padding: 9px; font-weight: 500; border: 1px solid #3E4245;}
 .home_mobile_sec4 div{ padding-bottom: 40px;}
 .home_mobile_sec4 div:last-child{  padding-bottom: 0px;}
 .bannerdots{width: 100%; position: absolute;    bottom: 10px;    text-align: center;}
  .swipe{position: relative;}
 .home_mobile_sec4 span img, .home_mobile_sec6 span img, .home_mobile_sec7 img, .home_mobile_sec7 iframe{ display: block;}
 .home_mobile_banner span{ display: block; width: 100%; cursor: pointer;}
 .home_mobile_banner span a{ display: block;}
 .home_mobile_banner span img{ display: block; width: 100%;}
 .bannerdots button div{ background: #fff;}
 .bannerdots button{ background-color: inherit;}
 .home_mobile_sec12{ width: 100%; padding-top: 40px; display: inline-block;}
 .home_mobile_sec12 div{ margin: 0;}
 .home_mobile_sec12 h2{font-size: 22px; line-height: 1.2; font-weight: 600; }
 .home_mobile_sec16{ width: 100%; padding-top: 45px; position: relative;   }
 .home_testi_sec16{ box-shadow: 0px 0px 6px rgb(49 49 49 / 16%); overflow: hidden; border-radius: 5px;
  background-color: #fff; width: 100%; position: relative;min-height: 370px;    margin-top: 5px;}
  .home_testi_bottom { width: 100%;    background: #c4601d; position:absolute; bottom: 0;
    min-height: 70px;padding-top: 10px; }
  .home_testi_bottom span{ width: 90px; position: absolute; height: 90px; border-radius: 50%;
     overflow: hidden;    right: 20px;top: -50px;background: #fff; }
     .home_testi_sec16 .col{padding:40px;}
     .home_testi_sec16 .col abbr{    display: block; width: 34px;}
     .home_testi_sec16 .col p{ font-size: 13px;    letter-spacing: 0.5px;}
     .home_testi_bottom h4{ font-size: 19px; color: #fff ; padding-bottom:4px; text-align: right;
       padding-right: 125px;}
     .home_testi_bottom ul{text-align: right; padding-right: 125px; padding-bottom: 5px;}
     .home_testi_bottom ul li { color: #fff;  font-size: 13px; }
     .home_testi_bottom ul li b{ width: 14px; margin-left: 2px;}
     .home_mobile_sec16 h2{    font-size: 30px; padding-bottom: 10px; letter-spacing: 0.25px;
      text-transform:uppercase; font-weight: 400; }
      .carousel_item_padding{padding:0 12px;}
      .home_mobile_sec18{ width: 100%;     margin-top: 45px; position: relative;  }
      .home_mobile_sec18 .journal_colm h2{font-size: 28px; float: left;padding-bottom:5px; letter-spacing: 0.25px;
   font-weight: 400; font-family: 'Bell MT'; color: #222222;}
  .home_mobile_sec18 .journal_colm a{font-size: 15px;  color: #575757; text-align: center;
     letter-spacing: 1.25px; font-family: 'Barlow';}
    .home_mobile_sec18 .journal_colm a:hover{ color: #222222;}
    .kama_journal_box { padding: 8px; border-radius: 5px;  height: 100%;}		
    .kama_journal_box span {display: block;padding-bottom: 15px; cursor: pointer;}		
    .kama_journal_box h4 {    color: #222222; cursor: pointer; font-size: 18px; font-family: 'Bell MT'; line-height: 24px; font-weight: 500;padding-bottom: 10px;}	
    .kama_journal_box span img{ display: block;    aspect-ratio: 1;}
    .kama_journal_box p{ font-size: 15px;margin-bottom: 10px; font-family: 'Barlow'; color: #575757;}		
    .kama_journal_box a{    display: block;  letter-spacing: 1.25px;
font-size: 13px;color: #3e4245 !important;  font-weight: 400; cursor: pointer; font-family: 'Barlow';}
    .home_mobile_sec18 .col_8{ padding-top: 10px; padding-bottom: 15px;    height: 100%; display: flex;}
      .home_mobile_sec18 .row{ margin: 0 -5px;    position: relative;}
      .home_mobile_sec7 div span span{ margin-bottom:0px;}
      .home_mobile_sec11{padding: 10px; margin-top: 60px;
 text-align: center; border-radius: 5px;
 margin-top: 4.5rem;
} 
 .home_mobile_sec11 p{ margin: 0;font-family: 'Bell MT';    font-size: 18px;    color: #222222;
  font-weight: 300;letter-spacing: 0.25px;}
 .home_mobile_sec11 div::before{ content: "";position: absolute;border: 1px solid #c4601d;width: 100%;
 height: 100%;left: 0;top: 0;}
 .home_mobile_sec11 div{ position: relative; padding: 30px;}
 .fwfeed{padding-bottom:50px; padding-top: 0px; width: 100%;}
 .fwfeed h2{    font-size: 30px;padding-bottom: 15px; letter-spacing: 0.25px; text-transform: uppercase;
  font-weight: 400; line-height: 1.2;}
  /* stylelint-disable selector-list-comma-newline-after */
.fwfeed_mobile{ padding-top: 0px; padding-bottom: 0px;}
.fwfeed_mobile h2{    font-size: 20px; text-transform: uppercase;font-weight: 600; padding-bottom: 12px; text-align: left;}
.fwfeed_mobile h3{    padding: 20px; background: #f9f4e8; border-radius: 5px;width: 100%;display: block;position: relative;text-align: center;}
.fwfeed_mobile h3 abbr{    letter-spacing: 0.15px; color: #C4601D; font-size: 23px; padding-bottom:8px; display: block;}
.fwfeed_mobile h3:before {background-image: url(/images/motif1.inline.svg);content: ""; position: absolute; width: 155px;top: -47px;height: 47px; background-size: cover;transform: translate(-50%, 0); left: 50%;}
.fwfeed_mobile h3:after {background-image: url(/images/motif1.inline.svg);content: "";position: absolute;width: 155px;bottom: -47px;height: 47px;background-size: cover;transform: translate(-50%, 0) rotate( 180deg);left: 50%;}
.fwfeed_mobile h3 p{ font-size: 14px; letter-spacing: 0.15px; margin-bottom: 0; line-height: 1.7; }
.anniversary_sec12 div{ display: block !important;}
.anniversary_sec12{ width: 100%; text-align: center;     display: flow-root; padding: 15px 12px 31px;
  font-size: 18px;letter-spacing: 1.25px;line-height: 1;    font-weight: 600;     background: #f9f8f8; color: #c4601d; position: relative;}
.anniversary_sec12 h2{font-size: 26px;text-align: center; font-weight: 500;    line-height: 1;color: #313131; letter-spacing: 0.25px; }
  .anniversary_sec12 h2 a{    font-size: 12px;font-family: 'Open Sans'; box-shadow: 0px 0px 16px rgb(82 53 40 / 16%);
    background: #c4601d;color: #fff;text-transform: uppercase;display: inline-block;
 padding: 8px 12px;border-radius:5px;    letter-spacing: 1.25px; font-weight: 600;}
 .anniversary_sec12 h2 sup{    top: -0.2em;    font-size: 26px;}
  .anniversary_sec12 h2 abbr{ display: block;}
.anniversary_sec12 span{ position: relative; background: #c4601d;color: #fff;  padding: 10px 12px;  display: inline-block;border-radius: 10px;margin: 0 10px;}    
.anniversary_sec12 span:before{ content: "Hours"; position: absolute;font-size: 13px; left: 50%; bottom: -18px; text-align: center; color: #c4601d; transform: translate(-50%, 0); letter-spacing: normal;}
.anniversary_sec12 span.a_sec12_2:before{content: "Minutes";}
.anniversary_sec12 span.a_sec12_3:before{content: "Seconds";}
.anniversary_sec12 span.a_sec12_4:before{content: "Days";}
.anniversary_sec12 > div{display: flex;justify-content: center;align-items: center;}
.home_mobile_banner span div div{ display: block !important;}
.home_mobile_sec2 video, .home_mobile_sec3 video{ display: block;}
.home_mobile_sec3_sec3 a{ display: flex;flex-direction: column;}
.home_mobile_sec3_sec3, .home_mobile_sec2_sec2{ display:flow-root; width: 100%;}
.categoryList{display: flex;}
.categoryList a h5{position: absolute; top: 50%; left: 50%; transform: translate(-50% , -50%); text-transform: uppercase; cursor: pointer; letter-spacing: 1.25px; font-size: 13px; font-family: Open Sans; font-weight: 600; text-align: center; width: 100%; padding: 0 10px; color: #fff; }
.categoryList li{position: relative; width: 50%; float: left; padding: 0 7px; margin-bottom: 14px;}
.sliderContainer{position: relative;}
 @media (min-width: 992px){
  .homeBanner{
    aspect-ratio: 7 / 2;
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .leafSet{
    position: relative;
  }

  .leftLeaf{
    position: absolute;
    left: 0;
    width: 100%;
    height: auto;
    max-width: 14rem;
    top: -8rem;
  }

  .categoryList li{padding: 0px 10px; margin: 0 0.5px; margin-bottom: 15px;}
  .fwfeed_mobile{ padding-top: 0px; padding-bottom: 60px;}
  .fwfeed_mobile h3{ margin: 0; padding: 25px;}
  .fwfeed_mobile > div{align-items: center;}
  .fwfeed_mobile h3:before{    width: 218px; top: -66px; height: 66px;}
  .fwfeed_mobile h3:after{    width: 218px; bottom: -66px; height: 66px;}
  .fwfeed_mobile h2{    font-size:30px;    font-weight: 400;padding-bottom: 15px;    letter-spacing: 0.25px;}
  .fwfeed_mobile h3 abbr{    font-size: 33px;    line-height: 1.2; letter-spacing: 0.25px;}
 .home_mobile_sec12 .home_mobile_recent{ position: relative; margin: 0 -8px;}
   .handleback{ left:15px;}
   .handlenext{ right:15px;}
   .bannerdots_button button{position: absolute;top: 50%; transform: translate(0, -50%); transition: all 0.32s ease-out;   color: #c4601d;
    background: rgb(249 244 232);; width: 42px; height: 42px;border-radius: 50%;}
    .bannerdots_button button:hover{background: hsl(42deg 59% 94% / 90%);}
    .bannerdots_button button svg{     font-size: 32px; width: auto;   height: auto; opacity: 1;fill: #c4601d;}
  .home_mobile_sec2 li, .home_mobile_sec3 ul li { color: #fff;}
  .home_mobile_sec5 ul{ margin: 0 -12px;}
  .home_mobile_sec18 .journal_colm abbr{    position: absolute;right: 0; top:9px;}
  .home_mobile_sec18 .row{ margin: 0 -8px;  }
  .kama_journal_box h4{    letter-spacing: 0.15px;  font-size: 21px; line-height: 30px;}
  .kama_journal_box{ padding: 20px;}
  .home_mobile_sec10 li{ width: 16%;}
  .home_mobile_sec10 h2, .home_mobile_sec8 h2, .home_mobile_sec5 h2, .home_mobile_sec4 h2, .home_mobile_sec1 h2{ font-size: 28px;padding-bottom: 15px;letter-spacing: 0.25px;font-weight: 400;font-family: 'Bell MT'; color: #222222;}
  .home_mobile_sec10{ margin-top: 60px;}
  .home_mobile_sec8 ul li{    width: 25%;    padding: 0 12px 15px !important;}
  .home_mobile_sec8 ul li div{ height: 110px;}
  .home_mobile_sec8 ul li div span{  margin: 0;}
  .home_mobile_sec8 ul li div h5{line-height: 1.5; letter-spacing: 1.25px; font-size: 14px; font-family: 'Barlow';
       padding-top: 18px; text-align: center; color: #575757;}
     .home_mobile_sec8 ul li div h5:hover{color: #222222;}
     .home_mobile_sec8 ul{ margin:0 -12px;}
     .home_mobile_sec8{ margin-top:60px; position: relative;}
     .home_mobile_sec8 abbr{ position: absolute; top:12px;text-align: left;width: auto; right: 0;}
     .home_mobile_sec7 h2{ font-size: 33px;  }
     .home_mobile_sec7 h2 span{ padding: 25px;line-height: 1.2;    letter-spacing: 0.25px; margin-bottom: 66px; margin-top: 66px;  
      font-weight: 400;}

     .home_mobile_sec7 h2:before{width: 218px;top: -66px;height: 66px;}
     .home_mobile_sec7 h2:after{width: 218px;bottom: -66px;height: 66px;}
     .home_mobile_sec7{ margin-top:45px;}
     .home_mobile_sec7 div a{font-size: 1rem; width: auto;min-width: 264px;margin: 0 auto; line-height: 24px;}
     .home_mobile_sec7 div h3{margin-bottom:15px; text-align: center;}
     .home_mobile_sec7 div span{ margin-bottom:20px;}
     .home_mobile_sec7 div span span{ margin-bottom:0px;}
     .home_mobile_sec7 div p{ margin-bottom: 20px;text-align: center;}
     .home_mobile_sec7 div{  padding-bottom: 60px;}
     .home_mobile_sec7 div:nth-child(3){ padding-bottom: 0;}
     .home_mobile_sec5 ul li{    width: 25%;    padding: 0 12px;    margin-bottom: 15px;}
     .home_mobile_sec7 ul{margin: 0 -12px;}
     .home_sec7_content{ padding: 20px; background-color: #fff; 
       width: auto; position: relative; height: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column; padding: 0px 4rem;}
      .home_mobile_sec6{ margin-top: 45px;}
      .home_mobile_sec6 h3{padding-bottom:10px; line-height: 1.3; font-family: 'Bell MT'; font-size: 24px;}
      .home_mobile_sec6 p{ margin-bottom: 20px;font-size: 14px; letter-spacing: 0.5px; font-family: 'Barlow'; text-align: center;}
      .home_mobile_sec6 a{ min-width: 231px; width: auto; font-size: 1rem; line-height: 24px; display: inline-block; margin-top: 10px;}
      .home_mobile_sec6 span{ margin-bottom: 0;}
      .home_mobile_sec4{margin-top: 60px; margin-bottom: 60px;}
      .home_mobile_sec4 span{ margin-bottom: 20px;}
      .home_mobile_sec4 h3{ font-size: 24px;   letter-spacing: 0.25px;    margin-bottom: 10px; text-align: center; cursor: pointer;}
      .home_mobile_sec4 p{font-size: 15px; letter-spacing: 0.5px;text-align: center; font-family: 'Barlow';}
      .home_mobile_sec4 div{ padding-bottom: 0px;}
      .home_mobile_sec4 a, .home_mobile_sec3 a{background-color: transparent; width: auto; color: #222222; padding: 11px;
        line-height: 24px; 
        border: 1px solid #3e4245;min-width: 264px;text-align: center;    margin: 0 auto;
        max-width: 264px; font-size: 1rem;  font-family: 'Barlow';}
        .home_mobile_sec4 .two_banner_btn:hover{
          border: 1px solid #ad3132;
        }
        .home_mobile_sec3 h3{ font-size: 30px; letter-spacing: 0.25px; 
          padding-bottom: 10px;line-height: 1.3;    font-weight: 400; color: #fff;}
        .home_mobile_sec3 p{   color: #fff; letter-spacing: 0.5px;font-size: 13px; line-height: 1.9;}
        .home_mobile_sec3 ul{ margin-bottom: 18px;}
        .home_mobile_sec3 a{    border: 1px solid #ffffff;}
        .home_mobile_sec3 span {    height: 350px;overflow: hidden; width: 100%; display: none;}
        .home_mobile_sec1 .col-8{ padding: 0 12px;width: 33.3333333333%;}
        .home_mobile_sec1 .row { margin: 0 -8px; padding-top: 0; overflow: inherit;    padding-bottom: 0;}
        .icon_see{ padding-top:5px;}
        .home_mobile_sec3{margin-top: 10px;height: 352px;  box-shadow: none;  border-radius: 0;    text-align: center; padding: 0;}
        .home_mobile_sec2{position: relative;display: block;
 width: 100%;padding: 0px 0;background-repeat: no-repeat;   
 background-size: contain;height: 352px;margin-bottom: 20px;text-align: center;}
 .home_mobile_sec2 span{ display: none;}
 .home_mobile_sec2 h3{ font-size: 30px; letter-spacing: 0.25px; padding-bottom: 10px;line-height: 1.3;    font-weight: 400; }
 .home_mobile_sec2 p{   letter-spacing: 0.5px; font-size: 13px;}
 .home_mobile_sec2 a{ width: auto;min-width: 260px;    background: #fff;color: #c4601d;    margin: 0 auto;}
 /* .home_mobile_sec2:before{    transform: rotate(-90deg);    left: -102px;top: 150px;}
 .home_mobile_sec2:after{content: ""; background-image: url(../../public/images/motif1.svg); width: 156px;height: 49px;
  transform: scaleX(-1) rotate(-90deg);position: absolute;  right: -102px;top: 150px; 
  background-size: cover;} */
   .home_mobile_sec1 span{ display:flex;}
   .bannerdots{ bottom: 10px;}
   .bannerdots button{margin: 0 5px !important;}
   .home_mobile_sec12 h2{font-size: 33px;
    letter-spacing: 0.25px;
    text-transform: capitalize;
    font-weight: 400;margin-bottom: 20px !important;}
    .prev_btn { position: absolute;    right: -30px;top: 50%;opacity: .7;}
.next_btn{position: absolute;  left: -30px;top: 50%; opacity: .7;}
.home_mobile_sec1{ position: relative;   }
.home_mobile_sec9 h2{font-size: 30px; font-weight: 400;letter-spacing: 0.25px;
  text-transform:uppercase; padding-bottom: 15px;}
  .home_mobile_sec9 div{ width: 49%; float: left; margin-bottom: 0;}
  .home_mobile_sec9 div:nth-child(2){ float: right;}
  .home_mobile_sec9 div iframe{ border-radius: 5px; display: block; margin-bottom: 15px;}
  .home_mobile_sec9 div p{ font-style: normal; line-height: 1.7;}
  .home_mobile_sec13{ margin-top: 0; padding-top: 0;}
  .home_mobile_sec14{ margin-top: 10px;}
  .home_mobile_sec15 .home_sec7_content{ margin: 0; z-index: 1; }
  .home_mobile_sec15{ margin-top: 60px;}
  .home_mobile_sec16 .row{ margin: 0 -8px; position: relative;}
  .home_mobile_sec15 span div{ display: block !important;}
 }


 @media (max-width: 767px){
  .leafSet{
    position: relative;
  }

  .leftLeaf{
    position: absolute;
    left: 0;
    width: 100%;
    height: auto;
    max-width: 8rem;
    top: -5rem;
    display: block;
  }

  .rightLeaf{
    position: absolute;
    right: 0;
    width: 100%;
    height: auto;
    max-width: 7rem;
    top: -14.4rem;
    display: block;
  }

  .home_mobile_sec8 ul li div h5{order: 1; letter-spacing: 1.25px;font-size: 12px;text-align: center; text-transform: uppercase;}
  .home_mobile_sec8 ul li div span{order: 2;}

  .home_mobile_sec8 h2{text-align: center;}
  .home_mobile_sec4 h2{text-align: center;}
  .home_mobile_sec10 h2{text-align: center;}

  .icon_see{font-size: 18px;}

  .home_mobile_sec8 abbr a{
    border: 1px solid rgb(62, 66, 69, 0.25);
  text-align: center;
  padding: 6px;
  margin-top: 1rem;
  margin-bottom: 1.25rem;
  display: block;
  font-weight: 500;
  }

  .home_mobile_sec18 .journal_colm a{
    border: 1px solid rgb(62, 66, 69, 0.25);
    text-align: center;
    padding: 6px;
    margin-top: 1rem;
    margin-bottom: 1.25rem;
    display: block;
    font-weight: 500;
  }

  .homeBanner{
    aspect-ratio: 11 / 15;
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .belowHeading{
    color: #3E4245;
    margin-bottom: 1.25rem;
    font-size: 2rem !important;
    font-style: normal;
    font-weight: 400;
    line-height: 52px;
    padding-top: 1rem;
  }
  
  .belowDescription{
    color: #3E4245;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;  
    margin-bottom: 2rem;
  }

  .anniversary_sec12{ padding-bottom: 25px;}
  .journal_colm{
    display: flex;
    flex-direction: column;
  }

  .journal_colm h2{
    text-align: center;
  }

  .journal_colm .icon_see{
    border: 1px solid rgb(62, 66, 69, 0.25);
    text-align: center;
    padding: 6px;
    margin-top: 1rem;
    margin-bottom: 1.25rem;
  }
  /* .anniversary_sec12{    padding: 10px 0 26px; font-size: 14px;} */
  .anniversary_sec12 span:before{    font-size: 12px;    bottom: -16px;}
  .anniversary_sec12 span{    padding: 8px 10px;}
  .anniversary_sec12 > div{display:inline-block;}
  .anniversary_sec12 h2{    font-size:19px;font-weight: 600;    top: 0; margin-right: 0;}
  .fwfeed_mobile h3 abbr{ text-transform: uppercase;     color: #313131;    font-size: 26px;}
  .fwfeed_mobile h3:before{ display: none;}
  .fwfeed_mobile h3:after{   display: none;}
  .fwfeed_mobile > div{    flex-direction: column-reverse;}
  .fwfeed_mobile h3{     background: none; padding: 0; margin-bottom: 15px;}
  .fwfeed_mobile h2{ display: none;}
  .fwfeed{padding-bottom:30px; padding-top: 5px; }
  .fwfeed h2{font-size: 20px; line-height: 1.2; font-weight: 600; padding-bottom: 12px;    letter-spacing: normal;
    text-transform: inherit;}
    .home_mobile_sec11{
      max-width: 280px;
      width: 100%;
      margin: auto;
    }
  .home_mobile_sec11 p{font-size: 20px;}
  .home_mobile_sec11 div{ padding:20px;}
  .home_mobile_sec18 .journal_colm abbr{ width: 100%;
    text-align: center; display: block;}
  .home_mobile_sec18{ margin-top: 40px;}
  .home_mobile_sec18 .journal_colm h2{ font-size: 24px; letter-spacing: normal;
    padding-bottom: 2px; font-weight: 500;}
  .carousel_item_padding{padding:0 7px;}
  .home_mobile_sec1 .row .col-9{padding:0 7px;}

  .home_mobile_sec3 {    margin-top: 10px; text-align: center;}
  .home_mobile_sec13{margin-top: 0;}
  .home_mobile_sec14{    margin-top: 5px;}
  .home_mobile_sec7 div span span{ margin-bottom: 0;}
  .home_mobile_sec15{    margin-top: 40px;}
  .home_mobile_sec15 .row{    flex-wrap: wrap-reverse;}
  .home_mobile_sec16{ padding-top: 40px;}
  .home_mobile_sec16 h2{ letter-spacing: normal;   font-size: 20px; text-transform:uppercase;
    padding-bottom: 2px; font-weight: 600;}
    .home_mobile_sec16 .row{    flex-wrap: nowrap; white-space: nowrap;  overflow: auto;    -webkit-overflow-scrolling: touch; overflow-scrolling: touch;
      -ms-overflow-style: none;  scrollbar-width: none; transform: translateZ(0);scroll-behavior: smooth;
  margin: 0 -7px;padding-top: 10px;padding-bottom: 15px;}
  .home_testi_sec16{white-space: normal; min-height: 360px;    margin-top:0px;}
  .home_mobile_sec16 .col-md-4{ padding: 0 7px;}
  .home_mobile_sec16 ::-webkit-scrollbar{ width: 0; height: 0;}
  .home_testi_sec16 .col{padding:20px 15px;}
  .home_testi_sec16 .col abbr{ width: 26px;}
  .home_testi_bottom span{    height: 60px;width: 60px;right: 15px; top: -30px;}
  .home_testi_bottom h4, .home_testi_bottom ul{ padding-right:82px;    font-size: 18px;}
  .home_mobile_sec9{    margin-top:5px;}
  .home_mobile_sec9 iframe{ margin-bottom: 10px; display: block;    border-radius: 5px;}
  .home_mobile_sec10{margin-top:25px;}
  .home_mobile_sec11{    margin-top: 4rem;}
  .home_mobile_sec2{ margin-top:0px; text-align: center;  padding: 20px 15px; box-shadow: 0px 0px 6px rgb(82 53 40 / 20%);  display: block;}
  .home_mobile_sec121{ padding-bottom: 10px;}
  .bannerdots_button {background: initial; position: absolute; bottom: 10px;  display: flex;  width: 100%; justify-content: center;}
    .bannerdots_button button{   border: 0; padding: 0;  background: initial;  margin: 0 4px !important;    flex:0 !important;}
      .bannerdots_button button div{ background-color: #fff;}
      .home_mobile_sec12 .home_mobile_recent{     -webkit-flex-wrap: nowrap; flex-wrap: nowrap;  white-space: nowrap;  overflow: auto;  padding-top: 10px; padding-bottom: 15px; -webkit-overflow-scrolling: touch;  overflow-scrolling: touch;  -ms-overflow-style: none;  scrollbar-width: none;  -webkit-transform: translateZ(0); transform: translateZ(0);  scroll-behavior: smooth;  margin: 0 -7px; margin-bottom: -15px;}
      .home_mobile_sec12 .home_mobile_recent .col-9{padding: 0 7px;}
      .home_mobile_sec12 h2{padding-bottom:5px;}
      .swipe .col{ min-height: 142px; text-align: center;}
      .home_mobile_sec4{ text-align: center;}
      .home_mobile_sec6, .home_mobile_sec7 { text-align: center;}
.home_mobile_sec18 .row{ margin: 0 -7px;     flex-wrap: nowrap; white-space: nowrap;  overflow: auto;
  -webkit-overflow-scrolling: touch; overflow-scrolling: touch; -ms-overflow-style: none; scrollbar-width: none; transform: translateZ(0); scroll-behavior: smooth;
  padding-top: 10px;padding-bottom: 15px; }
        .home_mobile_sec18 .row .col-9{   padding: 0 7px;}
        .home_mobile_sec16 .row .col-9{  padding: 0 7px;}
        .home_mobile_sec18 ::-webkit-scrollbar, .home_mobile_sec16 ::-webkit-scrollbar{ width: 0; height: 0;}
        .home_mobile_sec18 .row a{ display: block;}
        .home_mobile_sec18 .col_8{ white-space: normal;    padding-top: 0px; padding-bottom: 0px;}
        .anniversary_sec12 h2{    font-size: 19px;    line-height: 1.3;padding-bottom: 6px;}
        /* .anniversary_sec12{    padding: 10px 12px 26px;} */
        .anniversary_sec12 h2 a{display: table; margin: 5px auto 0;    padding: 8px 12px;}
        .kama_journal_box span img{    aspect-ratio: initial; height: auto;}
        .fwfeed_mobile{ padding-bottom: 40px;}

}
@media screen and (min-device-width: 400px) and (max-device-width: 767px) { 
  .bannerdots_button {bottom: 10px;}


}

.home_mobile_sec4 span a, .home_mobile_sec3 span a,  .home_mobile_sec6 span a, .home_mobile_sec7 span a{    padding: 0; margin: 0;
  border: 0; min-width: 100%; max-width: 100%;display: block;    background-color: inherit;}
  .home_mobile_sec7 span div{ display: block !important; padding: 0;}

  .anniversary_sec12 .anniversaryBgImg{ position: absolute; top: 0; left: 0; width: 100%; height: 100%;}
  .anniversary_sec12 abbr { position: relative; z-index: 1; }
 

  @media (min-width: 990px) {
    .image-1440 {
      display: block;
    }
  
    .rightLeaf{
      max-width: 12rem;
      top: -24.2rem;
    }
  
    .leftLeaf{
      max-width: 10rem;
      top: -6rem;
    }
  }
  
  @media (min-width: 1441px) and (max-width: 1920px) {
    .image-1920 {
      display: block;
    }
  
    .rightLeaf{
      max-width: 19rem;
      top: -40.6rem;
    }
  
    .leftLeaf{
      max-width: 20rem;
      top: -12rem;
    }
  }
  
  @media (min-width: 1921px) {
    .image-2560 {
      display: block;
    }
  
    .rightLeaf{
      max-width: 24rem;
      top: -52.4rem;
    }
  
    .leftLeaf{
      max-width: 26rem;
      top: -16rem;
    }
  }
